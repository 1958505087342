import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../admin/admin.service';
import { ResultService } from '../../result/result.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  public electionResultData: any;
  constructor(private resultService: ResultService,
    private spinner: NgxSpinnerService, private adminService: AdminService) { }

  ngOnInit(): void {
    this.getResult();
    this.getActiveElection();
    this.getDesignations();
  }


  public electionData: any;
  getActiveElection() {
    this.adminService.getWithOutToken('get-active-election.json').subscribe((res: any) => {
      console.log(res);
      this.electionData = res.record;
    }, err => {
      console.log(err);
    });
  }

  public results: any = [];
  public designation = [];
  getResult() {
    this.spinner.show();
    this.resultService.get('get-result.json').subscribe((res: any) => {
      console.log(res)
      this.electionResultData = res;
       //this.designation = ['ICA-AP Regional Board Members', 'Chairman', 'ICA-AP Vice President', 'ICA-AP President','ICA Global Youth Member- Africa','ICA Global Youth Member at Large-Asia and Pacific','ICA Global Youth Member -Europe','ICA Global Youth Member -Americas','Youth Representative'];  
      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    });

    console.log(this.designation)
  }

  getDesignations() {
    this.spinner.show();
    this.adminService.getWithOutToken('get-designations.json').subscribe((res: any) => {
      console.log(res)
      if(res){
          //res.sort((a, b) => a.name.localeCompare(b.name));
          res.forEach(element => {
              this.designation.push(element.name);
          });
      }
      this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    })
  }

  short(dgn) {
    if (!this.electionResultData || !this.electionResultData.record) {
      return [];
    }
    
    return this.electionResultData.record.filter(obj => obj.designation.name == dgn);
  }

  // short(dgn) {
  //   var value: any = [];
  //   value = this.electionResultData.record.filter(obj => {
  //     return obj.designation.name == dgn;
  //   });
  //   return value;
  // }
//   is_winned(val, i) {
//     var edl = this.electionResultData.election.election_details.find(x => x.designation == val.designation.id);
//     if (edl) {
//       if (i <= edl.no_of_posts) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }

//   }

//   hasDuplicateTotalVotes(results: any[], currentTotalVote: number, currentIndex: number): boolean {
//     for (let j = 0; j < results.length; j++) {
//         if (j !== currentIndex && results[j].total_vote === currentTotalVote ) {
//             return true; // If any other row has the same total vote, return true
//         }
//     }
//     return false; // If no other row has the same total vote, return false
// }

// Function to check for duplicate total votes
hasDuplicateTotalVotes(arr: any[], vote: number, index: number): boolean {
  return arr.some((item, idx) => item.total_vote === vote && idx !== index);
}

// Function to check if the candidate is a winner
  is_winned(val, i) {   
    if(val.total_vote > 0){
      var edl = this.electionResultData.election.election_details.find(x => x.designation == val.designation.id);
    
    if (edl) {
      if (i <= edl.no_of_posts) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    }
    

  }

  is_vote_valid(total_vote: number): boolean {
    return total_vote > 1;
  }
  


}
